class CardIntersectionObserver {
  constructor({ intersectionCardsCallback } = {}) {
    if (intersectionCardsCallback) {
      if (typeof intersectionCardsCallback === 'function') {
        this.intersectionCardsCallback = [intersectionCardsCallback]
      } else {
        this.intersectionCardsCallback = intersectionCardsCallback
      }
    }
    this.initCardObserver()
  }
  // 商卡位置监听监听器
  observer = null
  // 商卡的映射 [goods_id, cardIns]
  CardsMap = new Map()
  // 视口内商卡的列表 [goodsIndex, cardEl]
  intersectionCards = new Map()
  // 视口内商卡变化回调
  intersectionCardsCallback = [() => {}]
  // 记录视口内商卡
  intersectionCardChange(goodsIndex, goodsId, entry) {
    if (!goodsIndex) {
      return
    }
    if (entry.isIntersecting) {
      this.intersectionCards.set(goodsIndex, {
        cardEl: entry.target,
        cardIns: this.CardsMap.get(+goodsId),
      })
      // console.log(`${goodsIndex} is in view`)
    } else {
      this.intersectionCards.delete(goodsIndex)
      // console.log(`${goodsIndex} is out of view`)
    }
  }
  // 监听商卡位置变化
  watchCardIntersection(entries) {
    entries.forEach(entry => {
      // 缺少id，表示非商卡
      let goodsIndex = entry.target?.dataset?.index
      let goodsId = entry.target?.dataset?.id
      this.intersectionCardChange(goodsIndex, goodsId, entry)
    })
    if (this.intersectionCardsCallback) {
      this.intersectionCardsCallback.forEach(cb => cb(this.intersectionCards))
    }
  }
  // 初始化商卡位置监听
  initCardObserver() {
    if (typeof IntersectionObserver !== 'undefined') {
      this.observer = new IntersectionObserver(this.watchCardIntersection.bind(this), {
        root: null, // 使用视口作为根
        threshold: 0, // 触发回调的阈值
      })
    }
  }
  // 添加指定商卡监听
  observerCard(cardEl, cardIns) {
    if (cardEl && cardIns && this.observer) {
      this.observer.observe(cardEl)
      this.CardsMap.set(+cardIns?.item?.goods_id, cardIns)
    }
  }
}

export default CardIntersectionObserver
