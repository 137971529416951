<template>
  <component
    :is="FlowIndex"
    v-bind="componentAttrs"
    :results="results"
  />
</template>

<script>
/**
 * @file 异步导入流式组件并提供高度占位能力
 */
/* eslint-disable vue/one-component-per-file */

import { defineComponent, shallowRef, h } from 'vue'
import { useMapAttrs } from 'public/src/pages/common/vuex-composition.js'

const getBlock = (results) => {
  const { compTag = '', props = {} } = results?.[0] || {}
  // 高度计算占位是因为瀑布流计算高度时获取不到异步组件高度
  let height = ['FlowRankingListA', 'FlowRankingListA1', 'FlowRankingListB', 'FlowRankingListC'].includes(compTag) ? '9.706rem' : '6.4rem'
  if (props.placeHolderHeight) height = props.placeHolderHeight
  return defineComponent({
    render() {
      return h('div', { 
        style: [
          { 
            width: '100%' 
          },
          { 
            height 
          }
        ]
      })
    },
  })
}

export default defineComponent({
  name: 'AsyncFlow',
  props: {
    results: {
      type: Array,
      default: () => [],
    },
  },
  setup (props) {
    const Block = getBlock(props.results)
    const FlowIndex = shallowRef(Block)

    import(/* webpackChunkName: "plv2_FlowIndex" */'./Index.vue').then((component) => {
      FlowIndex.value = component.default
    })

    return {
      FlowIndex,
    }
  },
  computed: {
    componentAttrs() {
      return useMapAttrs(this.$attrs)
    },
  },
})
</script>
