<template>
  <div
    ref="productList"
    class="product-list-v2__list j-da-event-box j-product-list-info actived-newproductcard"
    code="productList"
    :data-tag_ids="catInfo.tag_ids"
    :data-cat="catInfo.child_cat_id"
    :data-attr_str="catInfo.attr_ids"
    :data-min-pri="catInfo.min_price"
    :data-max-pri="catInfo.max_price"
    :data-active-from="activityFrom"
  >
    <!-- 双列瀑布流列表 -->
    <WaterFall
      v-if="waterItems && waterItems.length"
      ref="WaterFall"
      :use-from="useFrom"
      :items="waterItems"
      :init-water-fall="ComponentState.ProductList.isFirstItem && localIndex === -1 || forceRefresh"
      :strictLeftToRight="ComponentState.ProductList.waterfallRenderDirectionLTR"
      :ssr="waterFallSsrConfig"
      :local-index="localIndex"
      :dynamic-insert-num="dynamicInsertNum()"
      v-model:deleteItemsFlag="ComponentState.productListDeleteFlag"
      @mounted="$emit('waterFallMounted')"
      @update:deleteItemsFlag="$emit('waterFallDeleteItems', $event)"
    >
      <template #default="{ item, index, nutareIndex }">
        <AsyncFlow
          v-if="item && item.isFlowData"
          :locals="locals"
          :results="item.results"
          :language="language"
          :use-from="useFrom"
          :list-abt-result="listAbtResult"
          :shein-club-info="sheinClubInfo"
          @clickSearchFilter="handleClickSearchFilter"
        />
        <!-- 点后推插坑 --搜索词卡片 -->
        <SearchWordAllCard 
          v-else-if="showSearchWordCardFromRec(item)" 
          transition-name="fade-slide"
          :language="language"
          :search-words="item.listData"
          :title-text="item.titleText"
          :after-card-info="item.afterCardInfo"
          :source-goods-id="item.sourceGoodsId"
          :result-content="item.resultContent"
          :trace-id="item.traceId"
          :ab-test-str="item.abTestStr"
        />
        <!-- 点后推插坑 --点推商品 -->
        <RecommendProductListItem 
          v-else-if="(item && item.type === 'recommend-for-you') || false"
          :index="reportIndexFun(nutareIndex, item)"
          :item="item"
          :hasHandleFilterBar="PageState.hasFilt"
          :product-item-config="productItemConfig"
          :feedback-index="feedbackIndex"
          :landing="landing"
          :language="language"
          :constant-data="constantData"
          :locals="locals"
          :cat-id="catId"
          :shein-club-info="sheinClubInfo"
          :list-abt-result="listAbtResult"
          :feedback-rec-ccc-config="feedbackRecCccConfig"
          :scenes="scenes"
          :use-from="useFrom"
          :report-metrics="reportMetrics"
          @cardMounted="productItemMounted"
          @openQuickAdd="handleOpenQuickAdd"
          @clickItem="({ goodsUrl, target }) => handleClickItem(item, index, goodsUrl, target)"
          @clickMoreBtn="handleFeedbackIndex"
          @longClick="handleFeedbackIndex"
          @clickSearchFilter="handleClickSearchFilter"
          @clickColor="handleClickColor"
          @openBuyBoxDrawer="openBuyBoxDrawer"
          @recPopupMounted="recPopupMounted"
          @operateInRecPopup="$emit('operateInRecPopup')"
        />
        <ProductListItem
          v-else-if="item"
          :ref="`productListItem_${index}`"
          :index="index"
          :origin-index="item.originIndex"
          :item="item"
          :class="getItemClass(index)"
          :hasHandleFilterBar="PageState.hasFilt"
          :cat-id="catId"
          :select-id="selectId"
          :product-item-config="productItemConfig"
          :feedback-index="feedbackIndex"
          :landing="landing"
          :language="language"
          :constant-data="constantData"
          :locals="locals"
          :shein-club-info="sheinClubInfo"
          :list-abt-result="listAbtResult"
          :feedback-rec-ccc-config="feedbackRecCccConfig"
          :scenes="scenes"
          :use-from="useFrom"
          :report-metrics="reportMetrics"
          :analysisData="analysisData"
          :isSearch="isSearch"
          @cardMounted="productItemMounted"
          @openQuickAdd="handleOpenQuickAdd"
          @clickItem="({ goodsUrl, target }) => handleClickItem(item, index, goodsUrl, target)"
          @clickCheckout="handleClickCheckout"
          @clickMoreBtn="handleFeedbackIndex"
          @longClick="handleFeedbackIndex"
          @clickSearchFilter="handleClickSearchFilter"
          @clickColor="handleClickColor"
          @openBuyBoxDrawer="openBuyBoxDrawer"
          @recPopupMounted="recPopupMounted"
          @operateInRecPopup="$emit('operateInRecPopup')"
          @exposedCard="$emit('exposedCard', $event)"
          @videoMounted="handleVideoMounted"
        />
      </template>
    </WaterFall>
    <!-- 泛列表新增购物车浮窗icon -->
    <!-- 注意：列表业务场景接入productList.vue组件， 不要重复引入购物车浮窗实例 -->
    <template v-if="showCartComponents">
      <template v-if="hitQuickCart() || hitListCartExpand()">
        <QuickCart
          v-if="showCartBag()"
          ref="cartBag"
          :scene="quickCartScene"
          :lure-scene="quickCartLureScene"
          :use-from="useFrom"
          :search-feedback-visibility="searchFeedbackVisibility"
          :disabled-cart-tag-tips-showtime="disabledCartTagTipsShowtime"
          :page_from="getPageFrom"
          :state="quickCartState"
          :is-open-mini-cart="isOpenMiniCart()"
          :is-expand-cart="Boolean(hitListCartExpand())"
          :is-custom-trigger-expand-cart="!hitListCartExpandByType('cart')"
          :style="{
            '--quick-cart-offset-y' : isExpandQuickCart ? '0' : undefined,
            '--feedback-quick-cart-offset-y': isExpandQuickCart ? '2.0803rem' : undefined,
            '--quick-cart-wrapper-bottom': isExpandQuickCart ? '1.173rem' : undefined,
          }"
          @click-cart="handleClickCartBag"
          @mounted="quickCartMounted"
        />
      </template>
      <template v-else>
        <CartBag
          v-if="showCartBag()"
          ref="cartBag"
          :use-from="useFrom"
          :search-feedback-visibility="searchFeedbackVisibility"
          @click-cart="handleClickCartBag"
        />
      </template>
    </template>
    <!-- 列表为空数据时，父亲设置了display none，因而需要设置appendtobody -->
    <AgeLimitDialog
      v-if="showAgeLimit"
      :is-search-result-page="isSearchResultPage"
      :store-code="catInfo.store_code"
      :append-to-body="true"
      :visible="showAgeLimit"
      :title="language.SHEIN_KEY_PWA_24889"
      :content="language.SHEIN_KEY_PWA_24890"
      :ok-text="language.SHEIN_KEY_PWA_24891"
      :cancel-text="language.SHEIN_KEY_PWA_24892"
      @expose="onAgeLimitDialogExpose"
      @ok="onAgeLimitDialogOk"
      @cancel="onAgeLimitDialogCancel"
      @close-from-icon="onCloseFormIcon"
    />

    <ClientOnly>
      <!-- buy box -->
      <BuyBoxDrawer
        v-if="showBuyBoxDrawer"
        ref="refsBuyBoxDrawer"
        @onReady="handleBuyBoxDrawerReady"
      />
      <!-- 获取queryTs用 不渲染 ssr不需要 -->
      <div
        id="j-query-ts-container"
        style="display: none;"
        :data-query-ts="queryTs"
      ></div>
    </ClientOnly>
  </div>
</template>

<script>
import { defineComponent, nextTick, defineAsyncComponent } from 'vue'
import { mapState, mapActions } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { SIMetric } from 'public/src/pages/common/monitor/index.js'
import productItemMixin from 'public/src/pages/components/product/item_v2/js/mixin'
import clickPushBackSearchMixin from '@/public/src/pages/product_list_v2/js/clickPushBackSearchMixin' // 泛列表点后推搜索词mixins
import { ClientOnly } from '@sheinfe/vue-client-only'
import WaterFall from 'public/src/pages/components/product/WaterFall'
import ProductListItem from './ProductListItem'
import AsyncFlow from './flow/AsyncFlow.vue'
import cardRecommendMixin from '../js/cardRecommendMixin'
import { ageLimitAnalysis } from 'public/src/pages/components/age-limit-dialog'
import { handleClickSearchFilter } from '../js/search.common'
import { debounce, throttle } from '@shein/common-function'
import { getWaterItems, PAGE_NAME_MAP_PAGE_KEY, getListPageInfo, detectInactivity } from '../js/utils'
import { getCartNumBySkc } from '@/public/src/pages/product_app/util/getCartNum.js'
import { showSurveyComponent } from 'public/src/pages/components/product/carrier_ui/survey-question/utils'
import {
  emitClickProductCardInList,
  emitOpenQuickCartResultInList,
  emitOpenQuickCartInList,
  emitOpenQuickCartInPickList,
  emitOpenQuickCartResultInPickList,
} from 'public/src/pages/mounting/eventBus/eventLib/eventEmit.js'

import { setUserActionTrackerDataOnce } from 'public/src/pages/common/userActionTracker/core/index.js'
import { cartTagTip } from '@shein-aidc/bs-sdk-cart-tag-tip'
import { getQuickAddCartInstance, plugins } from 'public/src/pages/components/product/quick_add_v3/common.js'
import { QuickAddCartState } from 'public/src/pages/components/product/quick_add_v3/plugins/syncGlobalData/index.js'
import { parseQueryString } from '@shein/common-function'
import { CardAutoPlayVideoControl } from '../../components/product/item_v3/components/ProductCardAutoplayVideo/CardAutoplayVideoControl'
import CardIntersectionObserver from '../js/CardIntersectionObserver'

const daEventExpose = daEventCenter.getExposeInstance()
export default defineComponent({
  name: 'ProductList',
  components: {
    ClientOnly,
    WaterFall,
    ProductListItem,
    AsyncFlow,
    AgeLimitDialog: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "plv2_AgeLimitDialog" */ 'public/src/pages/components/age-limit-dialog/Index.vue'
      )),
    CartBag: defineAsyncComponent(() => import(/* webpackChunkName: "CartBag" */ 'public/src/pages/product_list_v2/components/CartBag/index.vue')),
    QuickCart: defineAsyncComponent(() => import(/* webpackChunkName: "QuickCart" */ 'public/src/pages/common/quick_cart/index.vue')),
    BuyBoxDrawer: defineAsyncComponent(() => import(/* webpackChunkName: "plv2_BuyBoxDrawer" */ 'public/src/pages/common/BuyBoxPopup/BuyBoxDrawer.vue')),
    RecommendProductListItem: defineAsyncComponent(() => import(/* webpackChunkName: "plv2_RecommendProductListItem" */ 'public/src/pages/product_list_v2/components/RecommendProductListItem.vue')),
    SearchWordAllCard: defineAsyncComponent(() => import(/* webpackChunkName: "plv2_SearchWordAllCard" */ 'public/src/pages/product_list_v2/components/CardRecommend/UI/SearchWordAllCard.vue')),
  },
  mixins: [productItemMixin, cardRecommendMixin, clickPushBackSearchMixin],
  provide(){
    return {
      listAbtResult: this.listAbtResult
    }
  },
  props: {
    noMoreData: Boolean,
    landing: Boolean,
    curPageRefresh: Boolean,
    getItemClass: {
      type: Function,
      default: () => {},
    },
    searchFeedbackVisibility: {
      type: Boolean,
      default: false,
    },
    activityFrom: {
      type: String,
      default: 'goods_list',
    },
    productsOriginData: {
      type: Array,
      default: () => [],
    },
    switchBtn: { // 适用于同一个页面有多个该ProductList实例【搜索页面的上位词商品搜索--hympernymGoods】
      type: Object,
      default: () => ({
        productsOriginOpen: false, // 控制不同数据源商品
        onlyOne: false, // 多个实例只使用一个年龄限制弹层、购物弹框
      }),
    },
    showSkeleton: {
      type: Boolean,
      default: false,
    },
    hypernymProductSum: {
      type: undefined || Number,
      default: undefined
    },
    // 标识该组件是被谁引用了，主要解决cartbag 加车的问题
    useFrom: {
      type: String,
      default: '',
    },
    reportMetrics: {
      type: Object,
      default: () => ({}),
    },
    // 执行点后推动态插坑
    addRecommendProductHandle: {
      type: Function,
      default: function() {},
    },
    // 点后刷callback
    triggerComeBackRefreshList: {
      type: Function,
      default: () => {},
    },
    // 点后刷abt callback
    getClickRefreshAbt: {
      type: Function,
      default: () => {},
    },
    removeUnexposedGoods: {
      type: Function,
      default: () => {},
    },
    resolveDelayResolveNewData: {
      type: Function,
      default: () => {},
    },
    localIndex: {
      type: [String, Number],
      default: -1,
    },
    // 点后推个数
    // isShowRecommendComp: {
    //   type: [String, Number],
    //   default: 0,
    // },
    // 经过商详过滤的后真实需要点推的数量
    changeRecommendNum: {
      type: [String, Number, undefined],
      default: 0,
    },
    featureKey: {
      type: String,
      default: '',
    },
    queryTs: {
      type: String,
      default: '',
    },
    isTopTrendResultPage: {
      type: Boolean,
      default: false,
    },
    oneClickPayGuideDialogShow: {
      type: Boolean,
      default: false,
    },
    forceRefresh: {
      type: Boolean,
      default: false,
    },
    openDetectInactivity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    this.slotCount = 0
    return {
      adultDialogOkClicked: false,
      feedbackIndex: -1,
      showSelect: false,
      selected: {},
      buyBoxGoodsId: '',
      needEmitAddBag: false, // hack code，处理上位词加车
      disabledCartTagTipsShowtime: true,
      showBuyBoxDrawer: false,
      showSurveyComponent: false, // 展示问卷调查组件
      // isShowRecommendComp: 0, // 点后推动态插坑个数
      cardAutoPlayVideoControl: null,
      cardIntersectionObserver: null, // 监听视口内商卡
      initVideoList: [], // 比Control先初始化的视频列表
      showCartComponents: false, // 控制延迟展示悬浮购物车
      isExpandQuickCart: false, // 是否为拉通态悬浮购物车
    }
  },
  computed: {
    ...mapState('productList_v2', [
      'PageState',
    ]),
    // 针对信息流落地页动态插坑个数
    // 增加搜索结果页&&真实&&选品列表的支持, wiki：pageId=1470253023
    // dynamicInsertNum() {
    //   // 增加搜索结果页&&真实&&选品列表的支持
    //   return +this.isShowRecommendComp
    //   // let { flowInterestReco } = this.listAbtResult
    //   // return +flowInterestReco?.p?.InterestReco || 0
    // },
    waterFallSsrConfig() {
      return this.switchBtn.productsOriginOpen
        ? { columns: 2, itemQuantity: this.goodsOne.length }
        : undefined
    },
    isSearchResultPage() {
      const { type } = this.catInfo
      return type === 'search'
    },
    isStoreSearchPage() {
      return this.catInfo?.search_type === 'store'
    },
    isListPage() {
      if (this.isStoreSearchPage || this.isSearchResultPage) return false
      return this.catInfo?.type !== 'store'
    },
    quickCartLureScene() {
      if (this.isStoreSearchPage) return 'list_search'
      if (this.isListPage) return 'list'
      return ''
    },
    quickCartScene() {
      if (this.isStoreSearchPage) return 'page_list_search'
      if (this.isListPage) return 'page_list_hovercart'
      return ''
    },
    showAgeLimit() {
      const showAgeLimit = (!this.switchBtn.onlyOne &&
        this.productItemConfig.showAdultProductDialog &&
        this.catInfo.hasAdultProduct &&
        !this.showSkeleton &&
        !this.adultDialogOkClicked
      )

      this.setDialogShowStatus?.({ dialogName: 'ageLimitDialogShow', result: showAgeLimit })
      return showAgeLimit
    },
    // 商品项配置
    productItemConfig() {

      // v.趋势搜索结果页屏蔽该功能
      if (this.isTopTrendResultPage) {
        return {
          ...this.ComponentState?.ProductList?.config || {},
          showFeedback: false,
          showSearchFilters: false,
        }
      }

      return this.ComponentState?.ProductList?.config || {}
    },
    FlowData() {
      return this.ComponentState?.ProductList?.FlowData || {}
    },
    flowDataV2() {
      return this.ComponentState?.ProductList?.flowDataV2 || {}
    },
    flowSurveyInfo() {
      return this.ComponentState?.ProductList?.flowDataV2?.flowSurveyInfo || {}
    },
    analysisData() {
      return this.ComponentState?.ProductList?.analysisData || {}
    },
    scenes() {
      return this.analysisData.scenes || ''
    },
    firstIn() {
      return this.catInfo?.requestType === 'firstload' || this.catInfo?.requestType === 'switchTab'
    },
    // 当前页面的真实分类id（类目ID）
    catId() {
      return this.catInfo?.cat_id || this.catInfo?.entity_id
    },
    // 当前选品列表或者shein-picks(选品id)
    selectId() {
      return this.catInfo?.select_id
    },
    goodsOne() {
      if (this.switchBtn.productsOriginOpen) {
        return this.productsOriginData
      } else {
        return this.goods
      }
    },
    waterItems() {
      // 测试日志，用来观察点后推插坑是否存在重复渲染
      // console.log('waterItemswaterItemswaterItems');
      return getWaterItems({
        goods: this.goodsOne,
        flowMap: this.flowMap || {},
        useFrom: this.useFrom,
        noMoreData: this.PageState?.noMoreData || false,
        flowSurveyInfo: this.flowSurveyInfo,
        showSurveyComponent: this.showSurveyComponent,
        fashionStorePosition: this.fashionStorePosition, 
        trendContentPosition: this.trendContentPosition, 
        choiceContentPosition: this.choiceContentPosition, 
      })
    },
    feedbackRecCccConfig() {
      return this.cccConfig?.feeReco || this.cccConfig?.feedBackStyle || {}
    },
    // 时尚店铺内容体坑位
    fashionStorePosition() {
      const { listtrendshopEntry, searchtrendshopEntry } = this.listAbtResult || {}
      const { isSearch, isOnlyListSelectPage, isEntityListPage } = getListPageInfo(this.catInfo)
      if (!isSearch && !isOnlyListSelectPage && !isEntityListPage) return -1
      const firstContentAfterNum = isSearch ? parseInt(searchtrendshopEntry?.p?.searchtrendShop) : parseInt(listtrendshopEntry?.p?.listtrendShop)
      if (isNaN(firstContentAfterNum)) return -1
      return firstContentAfterNum
    },
    // 趋势内容体坑位
    trendContentPosition() {
      const { listtrendEntry, searchtrendEntry } = this.listAbtResult || {}
      const { isSearch, isOnlyListSelectPage, isEntityListPage } = getListPageInfo(this.catInfo)
      if (!isSearch && !isOnlyListSelectPage && !isEntityListPage) return -1
      const firstContentAfterNum = isSearch ? parseInt(searchtrendEntry?.p?.searchtrendEntry) : parseInt(listtrendEntry?.p?.listtrendEntry)
      if (isNaN(firstContentAfterNum)) return -1
      return firstContentAfterNum
    },
    // 品质店铺内容体坑位
    choiceContentPosition() {
      const { listchoiceStores, searchchoiceStores } = this.listAbtResult || {}
      const { isSearch, isOnlyListSelectPage, isEntityListPage } = getListPageInfo(this.catInfo)
      if (!isSearch && !isOnlyListSelectPage && !isEntityListPage) return -1
      const firstContentAfterNum = isSearch ? parseInt(searchchoiceStores?.p?.searchchoiceStores) : parseInt(listchoiceStores?.p?.listchoiceStores)
      if (isNaN(firstContentAfterNum)) return -1
      return firstContentAfterNum
    },
    // 是否是全局的搜索结果页
    isSearch() {
      const { isSearch } = getListPageInfo(this.catInfo)
      return isSearch
    }, 
    quickCartState() {
      if (this.isListPage) {
        return 'list'
      }

      if (this.isSearch) {
        return 'search'
      }

      return ''
    },
    getPageFrom() {
      return this.catInfo?.pageName || ''
    }
  },
  watch: {
    flowSurveyInfo: {
      handler(val) {
        if (typeof window === 'undefined') return
        this.showSurveyComponent = showSurveyComponent(val,  this.useFrom === 'pickedInfo' ? 2 : 1)
      },
      immediate: true
    },
    showAgeLimit: {
      handler(val) {
        this.$emit('ageLimitDialogChange', val)
      },
      immediate: true
    },
    waterItems() {
      if (typeof window === 'undefined') return
      // 每次新增商品后，重新确认需播放视频（处理点推）
      setTimeout(() => {
        this.cardAutoPlayVideoControl?.playVideo()
      }, 500)
    }
  },
  created() {
    this.exposeTriggerCartBagTip = false
    this.isRunedDetectInactivity = false
  },
  // hack code 处理从商详回来，因为keep alive导致悬浮购物车实例还是商详中的
  activated() {
    setTimeout(() => {
      const { cartBag } = this.$refs
      if (cartBag) {
        window._gb_list_cart_ = cartBag
      }
    }, 200)
    this.cardAutoPlayVideoControl?.throttlingWatchScroll()
    setTimeout(() => {
      this.cardAutoPlayVideoControl?.playVideo()
    }, 500)
  },
  deactivated() {
    this.cardAutoPlayVideoControl?.pauseVideo()
    this.cardAutoPlayVideoControl?.unWatchScroll()
  },
  mounted() {
    // 延迟展示悬浮购物车
    this.delayedShowCartBag()
    if(!this.useFrom) {
      // 更新没显示出来的列表里的悬浮购物车
      // eslint-disable-next-line no-unused-vars
      appEventCenter.on('recommendListCartBagAdd', (target) => {
        // this.$refs?.cartBag?.drop?.(target)
        // 因为这个时候利诱点标签的状态还没更新，手动添加个定时延迟在触发
        setTimeout(()=> {
          this.$refs?.cartBag?.updateCartNum()
          this.$refs?.cartBag?.enterPageInitStatus()
          // 销毁监听，防止eventBus带来的内存泄露, 有问题不可加
          // appEventCenter.off('recommendListCartBagAdd')
        }, 2000)
      })

      // this.listViewedTriggerCartBagTip()
      // this.listAddTriggerCartBagTip()
    }
    // 监听视口内商卡
    this.cardIntersectionObserver = new CardIntersectionObserver()

    // 不支持MediaSource时，不执行自动播放
    if (this.productItemConfig.showAutoplayVideo && typeof MediaSource !== 'undefined') {
      this.cardAutoPlayVideoControl =  new CardAutoPlayVideoControl({
        listContainerEl: this.$el,
        initVideoList: this.initVideoList,
        cardIntersectionObserver: this.cardIntersectionObserver,
      })
      this.cardAutoPlayVideoControl?.throttlingWatchScroll()
    }
    this.showSurveyComponent = showSurveyComponent(this.flowSurveyInfo,  this.useFrom === 'pickedInfo' ? 2 : 1)
    appEventCenter.on('surveyComponentExposed', this.onSurveyComponentExposed)
    setTimeout(() => {
      this.showBuyBoxDrawer = true
    }, 2000)

    // listViewedTriggerCartBagTip 和 listAddTriggerCartBagTip 触发的时机原本在 useFrom 无值的时候 
    // 有另外一个需求是在 picked-info-land 中也需要触发，所以这里做了个判断
    // if (this.useFrom === 'picked-info-land') {
    //   this.listViewedTriggerCartBagTip()
    //   this.listAddTriggerCartBagTip()
    // }

  },
  unmounted() {
    appEventCenter.off('surveyComponentExposed', this.onSurveyComponentExposed)
  },
  methods: {
    ...mapActions('productList_v2', ['setDialogShowStatus']),
    delayedShowCartBag() {
      if ('requestIdleCallback' in window) {
        requestIdleCallback(() => {
          this.showCartComponents = true
        })
      } else {
        setTimeout(() => {
          this.showCartComponents = true
        }, 2000) // 备用方案，延迟 2 秒加载
      }
    },
    onSurveyComponentExposed() {
      this.showSurveyComponent = showSurveyComponent(this.flowSurveyInfo,  this.useFrom === 'pickedInfo' ? 2 : 1)
    },
    // 是否展示购物车浮窗
    showCartBag() {
      // v.趋势搜索结果页屏蔽该功能
      if (this.isTopTrendResultPage) {
        return false
      }

      // 上位词存在，只展示上位词中的CartBag， 干掉搜索结果list中的CartBag
      // 两个同时存在，展示搜索结果list中的，干掉上位词list中的CartBag， 特殊处理上位词中的加车逻辑
      // 只存在搜索结果list中的， 干掉上位词list中的CartBag
      // console.log(this.switchBtn.productsOriginOpen, '111', this.productsOriginData, '2222', this.goods?.length, '3333', this.goodsOne.length)
      
      // 这个逻辑是为了解决WEBS-34281， 上位词组件引入了productList 组件，导致购物车浮窗利诱点相关功能异常
      // 两个同时存在，展示搜索结果list中的(搜索结果list会先触发渲染)
      if(this.switchBtn.productsOriginOpen && (this.productsOriginData?.length > 0) && (this.goods?.length > 0)) {
        this.needEmitAddBag = true // 标识这个时候两个同时存在，为了上位词加车用
        return false
      }
      // 哪个存在渲染哪个
      return this.productItemConfig.showCartBag && !this.constantData?.IS_RW && (this.goodsOne.length > 0)
    },
    getExposeType() {
      const { child_cat_id } = this.catInfo
      const expose_type = this.isSearchResultPage ? 4 : child_cat_id ? 2 : 1
      return expose_type
    },
    onAgeLimitDialogExpose() {
      const expose_type = this.getExposeType()
      ageLimitAnalysis.exposePopup({ expose_type })
    },
    onAgeLimitDialogOk() {
      this.adultDialogOkClicked = true
      const expose_type = this.getExposeType()
      const button_type = 1
      ageLimitAnalysis.clickPopup({ expose_type, button_type })
    },
    onAgeLimitDialogCancel() {
      const expose_type = this.getExposeType()
      const button_type = 2
      ageLimitAnalysis.clickPopup({ expose_type, button_type })
    },
    onCloseFormIcon() {
      const expose_type = this.getExposeType()
      const button_type = 3
      ageLimitAnalysis.clickPopup({ expose_type, button_type })
    },
    // 分步式搜索的点击回调, 锦囊搜索参考此交互 + PicTopNav2Query实验参考此交互
    handleClickSearchFilter,
    handleFeedbackIndex({ index, isShow }) {
      if(!isShow) {
        return 
      }
      this.hideFeedbackRecPopup() // cardRecommendMixin 中的方法
      this.feedbackIndex = index
    },
    handleClickItem(payload, index, goodsUrl, target) {
      const pageName =  window.SaPageInfo?.page_name
      const { src_module, src_identifier } = this.catInfo
      const actionId = setUserActionTrackerDataOnce(
        this.featureKey, 
        { 
          activity_name: 'click_goods_list', 
          activity_param: { 
            goods_list: [payload.goods_id, payload.goods_sn].join('`'),
          },
          page_param: {
            src_module,
            src_identifier,
          },
          page_name: pageName,
          timestamp: Date.now(),
        }, {
          to: {
            name: 'page_goods_detail',
            path: goodsUrl.split('?')[0], // 跳转下个页面的path
          },
          from: this.$route
        }
      )
      target.setAttribute('data-action-id', actionId)
      this.$emit('clickGoods', { ...payload, curClickIndex: index }) // curClickIndex记录当前点击商品的坑位
      emitClickProductCardInList({
        index,
        product: payload,
      })

      const triggerQuickCartsEndtime = Boolean(this.hitListCartExpand()) && Boolean(this.hitQuickCart()) && !this.hitListCartExpandByType('cart')
      if (triggerQuickCartsEndtime) {
        // this.log('点击商卡触发收起拉通态 ！')
        cartTagTip.triggerQuickCartsEndtime()
      }
    },
    async openBuyBoxDrawer ({ goods_id, cat_id, goods_sn, mall_code }) {
      if (!this.showBuyBoxDrawer) {
        this.showBuyBoxDrawer = true
      }
      const { pageName } = this.catInfo 
      const { searchgoodsCard, listgoodsCard } = this.listAbtResult
      const displayWindowAbtBranch = pageName === 'page_search' ? searchgoodsCard?.p?.searchGoods : listgoodsCard?.p?.listGoods
      if (this.buyBoxGoodsId !== goods_id) this.$refs?.refsBuyBoxDrawer?.refreshData?.()
      this.buyBoxGoodsId = goods_id

      await nextTick()
      await nextTick()
      this.$refs?.refsBuyBoxDrawer?.open?.({
        analysisConfig: { goodsId: goods_id },
        isPaidUser: this?.sheinClubInfo?.isPaid,
        requestParams: {
          goods_id,
          cat_id,
          goods_sn,
          mall_code,
          pageKey: PAGE_NAME_MAP_PAGE_KEY[pageName] || '',
          cccParams: {
            displayWindowAbtBranch // 开启新商卡橱窗ABT
          },
        },
      })
    },

    handleBuyBoxDrawerReady() {

    },

    // 打开快速加车
    handleOpenQuickAdd({ item, index, target, isRecommend = true, imgRatio, activityFrom }) {
      // 点刷逻辑request_early 发送请求并且保存 promise
      if (this.getClickRefreshAbt('request_early')) {
        this.getRefreshListPromise = this.triggerComeBackRefreshList({ actionType: 'quickAdd' })
      }
      this.$emit('clickStartAddQuick', { ...item, curClickIndex: index }) // 给点后推动态插坑用, curClickIndex记录当前点击商品的坑位
      const { goods_id, mall_code, goods_img, mobileVerticalView } = item
      const updateOneClickOrder = throttle({
        func: (params) => {
          this.$emit('oneClickPayComplete', params)
        },
        wait: 3000,
        options: {
          trailing: false
        }
      })
      const hypernymProductSum  = typeof(this.hypernymProductSum) === 'number' ? { hypernymProductSum: this.hypernymProductSum } : null
      const { src_module, src_identifier, src_tab_page_id, url_from } = this.catInfo
      // 一键购订单号
      const ocpBillNo = parseQueryString(location.search)?.billno
    
      // 一键购结束回调
      const ocpCallBack = (type) => {
        if (type === 'success') {
          // 支付成功
          updateOneClickOrder()
        }
      }
      getQuickAddCartInstance().open({
        goods_id,
        mallCode: mall_code,
      }, {
        featureConfig: {
          showBestDealLabel: true,
          showEstimatedPrice: !item.pretreatInfo?.suggestedSalePriceConfigInfo?.config?.cartHideEstimatedPrice,
          needClickToDetail: true
        },
        analysisConfig: {
          code: 'productList',
          sourceTarget: target || null,
          sa: {
            activity_from: activityFrom || this.activityFrom,
            mobileVerticalView,
          },
          postData: {
            useLocateLabelsAna: true
          },
          ...hypernymProductSum
        },
        detailUrlExtendParam: {
          showFeedbackRec: this.useFrom === 'RecommendDrawer' ? false : true, // FR-14861和商详约定
          src_module: url_from ? 'ads' : src_module,
          src_identifier: url_from ? `uf=${url_from}` : src_identifier,
          src_tab_page_id,
          pageListType: this.catInfo.pageListType,
        },
        extendedParam: {
          imgRatio,
          mainImg: goods_img,
        },
        callbacks: {
          onAddCartComplete: () => {
            if (QuickAddCartState?.addCartStatus) {
              this.$emit('quickAddSuccess', { goods_sn: item.goods_sn, index, checkoutNum: item.checkoutNum, addSuccess: true })
              this.debounceIt({
                target: this.$refs?.[`productListItem_${index}`]?.$el
              })
              this.$emit('clickQuickAddBtn', item)
            }
          },
          onJumpDetailClick: () => {
            // 记录点击 detail 跳转商详的情况
            this.isClickDetailLink = true
            if (QuickAddCartState?.addCartStatus || QuickAddCartState?.wishStatus) {
              this.useGoodId.push(goods_id)
            }
          },
          onDrawerStatusChange: async active => {
            // cardRecommendMixin方法
            this.handleFeedbackRecInfo({
              active,
              clickDetails: QuickAddCartState?.isClickDetails,
              goodsId: goods_id,
              addBagStatus: QuickAddCartState?.addCartStatus, 
              addWishStatus: QuickAddCartState?.wishStatus, 
              isRecommend
            })
            const eventBusParams = {
              product: item,
              clickDetails: QuickAddCartState?.isClickDetails,
              addBagStatus: QuickAddCartState?.addCartStatus,
              addWishStatus: QuickAddCartState?.wishStatus,
              isRecommend,
              index,
              pageType: this.catInfo.type,
            }
            const eventBusFuncList = []
            eventBusFuncList.push(active ? emitOpenQuickCartInList : emitOpenQuickCartResultInList)
            if (this.catInfo.type === 'pick') {
              eventBusFuncList.push(active ? emitOpenQuickCartInPickList : emitOpenQuickCartResultInPickList)
            }
            eventBusFuncList.forEach(func => func(eventBusParams))
            if (!active) {
              updateOneClickOrder({})

              // 点击 detail 到商详的，不会触发点刷
              if (this.isClickDetailLink) {
                this.isClickDetailLink = false
                return
              }

              // 点刷逻辑request_early 先删除
              if (this.getClickRefreshAbt('request_early')) {
                if (this.getRefreshListPromise) {
                  await this.getRefreshListPromise
                }

                this.removeUnexposedGoods()
              }

              // 点刷逻辑request_early_back 先删除
              if (this.getClickRefreshAbt('request_early_back')) {
                this.removeUnexposedGoods()
                await this.triggerComeBackRefreshList()
              }

              // 点推逻辑
              const recommendProduct = () => {
                // 针对是否执行点后推商品 （信息流（一期）, 搜索结果页｜真实列表｜选品列表（二期））
                let { flowInterestReco, ListClickReco, SearchClickReco, StoreClickReco } = this.listAbtResult
                const { isPickInfoPage, isSearch, isSelectListPage, isEntityListPage, isStorePage } = getListPageInfo(this.catInfo)
                // 针对真实和选品列表是否满足当前的入口控制
                let isEntryControl = (isSelectListPage || isEntityListPage) && (ListClickReco?.p?.ListClickRecoEntrance === '' || (ListClickReco?.p?.ListClickRecoEntrance === 'sbc' && this.catInfo?.entranceType === 'sbc') || (ListClickReco?.p?.ListClickRecoEntrance === 'category' && this.catInfo?.srctype === 'category'))
                let isNeedAddRecGood = (isPickInfoPage && flowInterestReco?.p?.InterestReco) || (isSearch && SearchClickReco?.p?.SearchClickRecoEntry) || (isEntryControl && ListClickReco?.p?.ListClickRecoEntry) || (isStorePage && StoreClickReco?.p?.StoreClickRecoEntry)
                if(isNeedAddRecGood && item?.type !== 'recommend-for-you') {
                  // 执行点后推商品插入
                  this.addRecommendProductHandle(false, true, {
                    clickDetails: QuickAddCartState?.isClickDetails,
                    goodsId: goods_id,
                    addBagStatus: QuickAddCartState?.addCartStatus,
                    addWishStatus: QuickAddCartState?.wishStatus,
                  })
                }
              }

              // eslint-disable-next-line vue/valid-next-tick
              await nextTick(() => {
                recommendProduct()
              })

              // 点刷逻辑request_early 点刷商品插入
              if (this.getClickRefreshAbt('request_early')) {
                this.resolveDelayResolveNewData()
              }

              // 点刷逻辑request_early_back 点刷商品插入
              if (this.getClickRefreshAbt('request_early_back')) {
                // 点刷逻辑request_early_back 点刷插入
                this.resolveDelayResolveNewData()
              }
            }
          },
          
        },
        plugins: [
          plugins.oneClickPayPlugin(ocpBillNo, ocpCallBack), 
          plugins.syncGlobalDataPlugin(),
          plugins.syncCartInfoPlugin({ disabledCartTagTip: true }),
        ]
      })
    },
    // 给上位词用
    injectsDropCart(target) {
      this.$refs?.cartBag?.drop?.(target)
    },
    // 处理重复注册addToBagSuccess， 导致的问题
    debounceIt: debounce({
      func: function ({ target }) {
        // 如果是反馈半屏列表里的cartbag public/src/pages/product_list_v2/components/RecommendDrawer.vue eventBus消息外层
        if(this.useFrom === 'RecommendDrawer') {
          appEventCenter.emit('recommendListCartBagAdd', { target })
        }
        // 如果是上位词存在，少抛出去，走外层productList中的debounceIt
        if((this.switchBtn.productsOriginOpen && this.needEmitAddBag) || !this.$refs?.cartBag) {
          this.$emit('dropCart', target)
        } else {
          this.$refs?.cartBag?.drop?.(target)
        }
      },
      wait: 20,
    }),
    setAnalysisData(requestType) {
      if (requestType !== 'nexpage') {
        const listEl = this.$refs.productList
        listEl.setAttribute('da-expose-code', this.analysisData.exposeCode)
        listEl.setAttribute('data-poskey', this.analysisData.scenes)
        listEl.setAttribute('data-traceid', this.analysisData.traceId || '')
        listEl.setAttribute('data-request_ext', this.analysisData.request_ext || '')
        listEl.setAttribute('data-dimension', this.analysisData.saDimension || '')
      }
      if (!this.switchBtn.productsOriginOpen) {
        const waterItems = this.waterItems || []
        waterItems.forEach((item, index) => {
          if (!item?.goods_id) {
            return false
          }

          // 这里通过两层 ref 取到最终的 Item 组件实例 
          const indexInOriginGoods = index
          const itemInstance = this.getProductListItemInstance(indexInOriginGoods)?.$refs?.[`goodsItem_${indexInOriginGoods}`]

          // 已经设置过商卡埋点属性的不再设置
          if (itemInstance?.setElAttrDone) return

          itemInstance?.setElAttr()

          // 加入监听列表
          nextTick(() => {
            this.cardIntersectionObserver?.observerCard(itemInstance?.$el, itemInstance)
          })  

          // 店铺场景插坑index
          if (this.useFrom === 'store') {
            if (itemInstance) {
              // 获取真实的index信息
              itemInstance.$el?.setAttribute?.('data-store-index', indexInOriginGoods - this.slotCount)
            } else if (this.flowMap?.[index]) {
              this.slotCount++
            }
          }
        })
      } else { // 上位词商品列表
        // 处理 productsOriginData 的productItem attr
        this.productsOriginData.forEach((item, index) => {
          if (!item.exposed) {
            const itemInstance = this.getProductListItemInstance(index)?.$refs?.[`goodsItem_${index}`]
            itemInstance?.setElAttr()
            item.exposed = true
          }
        })
      }

      return `${this.productItemConfig.itemDAEventExposeId}|2-10-2|1-5-1-5|1-36-1-15|1-5-1-26`
    },
    getSearchPriority(item) {
      return item?.ext?.recall_mark?.split('_')?.[1]
    },
    // 快加车尺寸切换
    changeSizeAttrs({ curSelectAttr }) {
      if (curSelectAttr.attr_value_name) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-33',
          target: {
            dataset: {
              attr_id: curSelectAttr.attr_id,
              attr_value_id: curSelectAttr.attr_value_id || '',
              attr_value: curSelectAttr.attr_value_name,
              location: 'popup',
            },
          },
        })
      }
    },
    // 点击切换颜色
    handleClickColor() {
      // 关闭相关推荐浮层
      this.hideFeedbackRecPopup() // cardRecommendMixin方法
    },
    recPopupMounted() {
      this.showFeedbackRecPopup() // cardRecommendMixin方法
    },
    hitQuickCart() {
      const isStoreSearchPage = this.catInfo?.search_type === 'store'
      const isListSearchPage = this.catInfo?.type === 'search'
      const isListPage = this.catInfo?.type !== 'store'

      if (isStoreSearchPage) {
        return this.listAbtResult.StoreCartPopover?.p?.StoreCartPopover
      }

      if (isListSearchPage) {
        return this.listAbtResult.SearchCartPopover?.p?.SearchCartPopover
      }

      if (isListPage) {
        return this.listAbtResult.ListCartPopover?.p?.ListCartPopover
      }

      return null
    },
    isOpenMiniCart() {
      if (this.isListPage) {
        return this.listAbtResult.ListCartPopup?.param?.ListCartPopup === 'on'
      }

      if (this.isSearch) {
        return this.listAbtResult.SearchCartPopup?.param?.SearchCartPopup === 'on'
      }

      return false
    },
    handleCartPopoverAbt({ isExpandCart = false, type }) {
      if (isExpandCart) {
        return this.hitListCartExpandByType(type)
      }

      const abtValue = this.hitQuickCart() || ''

      const viewedList = abtValue.includes('view') ? 'view' : ''
      const addList = abtValue.includes('add') ? 'add' : ''
      const exposeList = abtValue.includes('expose') ? 'expose' : ''

      const triggerTypeResult = [viewedList, addList, exposeList].filter(Boolean)

      return triggerTypeResult.includes(type) 
    },
    quickCartMounted() {
      this.listAddTriggerCartBagTip()
      this.listViewedTriggerCartBagTip()
    },
    // 悬浮购物车下单引导
    listViewedTriggerCartBagTip() {
      if (this.hitListCartExpand()) return

      if (this.handleCartPopoverAbt({ isExpandCart: false, type: 'view' })) {
        window._gb_cart_tag_tips_?.showtime({ lureScene: this.quickCartLureScene })
        return
      }
    },
    listAddTriggerCartBagTip() {

      const cartOrAddAbt = this.handleCartPopoverAbt({ isExpandCart: true, type: 'add' }) || this.handleCartPopoverAbt({ isExpandCart: true, type: 'cart' })
      if (cartOrAddAbt) {
        this.disabledCartTagTipsShowtime = false
        // this.log(`设置 disabledCartTagTipsShowtime 为 false`)
      }

      const hoverNum = this.handleCartPopoverAbt({ isExpandCart: true, type: 'hover' })

      const openDetectInactivity = this.openDetectInactivity && Number(hoverNum) && !this.isRunedDetectInactivity
      if (openDetectInactivity) {
        const stopTracking = detectInactivity(() => {
          window._gb_cart_tag_tips_?.showtime({ lureScene: this.quickCartLureScene })
          // this.log(`用户 ${hoverNum} 秒没操作, 展示拉通态悬浮购物车`)
          this.isRunedDetectInactivity = true
          stopTracking()
        }, Number(hoverNum))
      }

      if (Boolean(this.hitListCartExpand()) && Boolean(this.hitQuickCart())) {
        this.isExpandQuickCart = true
        document.querySelector('body').classList.add('show-expand-quick-cart')
        // cart 实验下不需要调用外部取消逻辑
        if (!this.hitListCartExpandByType('cart')) {
          document.addEventListener('scroll', this.handleProductListScroll)
        }
      } else {
        this.isExpandQuickCart = false
        document.querySelector('body').classList.remove('show-expand-quick-cart')

        if (!this.hitListCartExpandByType('cart')) {
          document.removeEventListener('scroll', this.handleProductListScroll)
        }
      }

      const abtValue = this.hitListCartExpand() || ''
      // 已经命中拉通态悬浮购物车需要阻止 非拉通态对 disabledCartTagTipsShowtime 的修改 
      if (!!abtValue) {
        return
      }

      // 这里为非拉通态的悬浮购物车
      if (this.handleCartPopoverAbt({ isExpandCart: false, type: 'add' })) {
        this.disabledCartTagTipsShowtime = false
      }
    },
    listExposeTriggerCartBagTip(currentTargetIndex) {
      const exposeNum = this.handleCartPopoverAbt({ isExpandCart: true, type: 'expose' })
      if (exposeNum && currentTargetIndex >= exposeNum && !this.exposeTriggerCartBagTip) {
        window._gb_cart_tag_tips_?.showtime({ lureScene: this.quickCartLureScene })
        this.exposeTriggerCartBagTip = true

        // this.log(`当前商品索引 ${currentTargetIndex} 大于等于 ${exposeNum} 展示拉通态悬浮购物车`)
        return
      }

      const abtValue = this.hitListCartExpand() || ''
      // 已经命中拉通态悬浮购物车需要阻止 非拉通态对 disabledCartTagTipsShowtime 的修改 
      if (!!abtValue) {
        return
      }

      if (this.handleCartPopoverAbt({ isExpandCart: false, type: 'expose' })) {
        const { ListCartPopover } = this.listAbtResult || {}
        const listCartStr = ListCartPopover?.p?.ListCartPopover || ''
        const exposeNumReg = /expose=(\d+)/
        const matchExposeNum = listCartStr.match(exposeNumReg)

        if (matchExposeNum) {
          const exposeNum = matchExposeNum[1] <= 10 ? 10 : Number(matchExposeNum[1])

          if (currentTargetIndex >= exposeNum && !this.exposeTriggerCartBagTip) {
            window._gb_cart_tag_tips_?.showtime({ lureScene: this.quickCartLureScene })
            this.exposeTriggerCartBagTip = true
          }
        }
        return
      }
    },
    // 命中拉通态的悬浮购物车
    hitListCartExpand() {
      const { isSearch, isOnlyListSelectPage, isEntityListPage } = getListPageInfo(this.catInfo)

      // mock
      // return 'add,hover=10,expose=10,fromcart'

      // 拉通态几种页面类型没命中的话直接返回 false
      // eslint-disable-next-line no-unreachable
      if ([isSearch, isOnlyListSelectPage, isEntityListPage].every(item => !item)) return ''
      // 当命中真实和选品列表场景的拉通态
      if ([isOnlyListSelectPage, isEntityListPage].some(item => item)) {
        return this.listAbtResult.ListCartExpand?.p?.ListCartExpand
      }
      // 当命中搜索结果页场景的拉通态
      if (isSearch) {
        return this.listAbtResult.SearchCartExpand?.p?.SearchCartExpand
      }
    },
    // log(str) {
    //   // 悬浮购物车拉通态测试使用 会移除
    //   // eslint-disable-next-line no-console
    //   console.log(`%c ${str} `, 'color: yellow; background: black; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
    // },
    // type 为触发拉通态悬浮购物车的类型: cart, add, expose, hover, fromcart
    hitListCartExpandByType(type) {
      const abtValue = this.hitListCartExpand() || ''

      // this.log(`当前拉通态悬浮购物车的 abt 为: ${abtValue}`)

      switch (type) {
        case 'hover': {
          const hoverNumReg = /hover=(\d+)/
          const matchHoverNum = abtValue.match(hoverNumReg)
          return matchHoverNum ? Number(matchHoverNum[1]) : false
        }
        case 'expose': {
          const exposeNumReg = /expose=(\d+)/
          const matchExposeNum = abtValue.match(exposeNumReg)
          return matchExposeNum ? Number(matchExposeNum[1]) : false
        }
        case 'cart': {
          return abtValue.trim().split(',').includes(type)
        }
        case 'add':
        case 'fromcart': {
          return abtValue.includes(type)
        }
        default: {
          return false
        }
      }
    },
    handleFromCart() {
      const isFromCart = this.handleCartPopoverAbt({ isExpandCart: true, type: 'fromcart' })
      if (isFromCart) {
        window._gb_cart_tag_tips_?.showtime({ lureScene: this.quickCartLureScene })
        // this.log(`当前来自购物车，展示拉通态悬浮购物车`)
      }
    },
    async handleClickCheckout({ goods_sn }) {
      const checkoutedInfo = await getCartNumBySkc([ goods_sn ]) || {}
      const cartId = checkoutedInfo[goods_sn]?.list?.[0]?.id

      if (cartId) {
        window._gb_app_.$router.push({
          name: 'cart',
          params: {
            cartId
          }
        })
      }
    },
    setAddCartType(index, type) {
      const itemInstance = this.getProductListItemInstance(index)?.$refs?.[`goodsItem_${index}`]
      itemInstance?.handleSetAddCartType(type)
    },
    // 点后推动态插坑的数量(为了兼容搜索结果)
    dynamicInsertNum () {
      // let { isSearch, isSelectListPage, isEntityListPage, isPickInfoPage } = getListPageInfo(this.catInfo)
      let { query, meta } = this.$route
      // 不用catInfo的原因是： 从个人中心切到category 再进列表的时候catInfo居然无值，导致判断页面类型异常
      let { isSearch, isSelectListPage, isEntityListPage, isPickInfoPage, isStorePage } = getListPageInfo({ search_type: query?.search_type, type: meta?.type })
      let { ListClickReco, SearchClickReco, flowInterestReco, StoreClickReco } = this.listAbtResult
      if(isPickInfoPage) {
        return flowInterestReco?.p?.InterestReco
      }

      if(isSelectListPage || isEntityListPage) {
        return this.changeRecommendNum || ListClickReco?.p?.ListClickRecoEntry || 0
      }

      if(isSearch) {
        return this.changeRecommendNum || SearchClickReco?.p?.SearchClickRecoEntry || 0
      }

      if(isStorePage) {
        return this.changeRecommendNum || StoreClickReco?.p?.StoreClickRecoEntry || 0
      }

      return 0
    },
    // 处理动态插坑后重新渲染问题
    productItemMounted(componetInstance, goodItems) {
      // 保留测试，拿来看商品项组件重复渲染问题
      // console.log('productItemMountedproductItemMountedproductItemMounted');
      if(this.constantData?.IS_RW) {
        return 
      }
      const { isPickInfoPage, isSearch, isSelectListPage, isEntityListPage, isStorePage } = getListPageInfo(this.catInfo)
      if(!componetInstance?.$el?.dataset?.id && componetInstance?.item?.type === 'recommend-for-you') {
        componetInstance?.setElAttr?.() // 设置埋点属性在dom上
      }
      // 针对动态插坑的推荐商卡
      if(componetInstance?.item?.type === 'recommend-for-you' && isPickInfoPage) {
        // 固定开头（infoflowRealtime）+scene_id（）
        componetInstance?.$el.setAttribute('data-recommend-info', 'infoflowRealtime_252')
        nextTick(()=> {
          setTimeout(() => {
            daEventExpose.subscribe({
              keycode: `picked_list_exposeCode\`2-3-2`,
              type: 'list',
            })
          }, 200)
        })
      }
      // 列表的点推埋点（和信息流的不一样）
      if(componetInstance?.item?.type === 'recommend-for-you' && (isSearch || isSelectListPage || isEntityListPage || isStorePage)) {
        let curGoods = this.waterItems?.length ? this.waterItems[this.localIndex] : '' // 当前触发点推的商品
        // 防止上一次点推商品的坑位值变化
        if(!componetInstance?.$el?.dataset?.realtimeFromCateId) {
          componetInstance?.$el.setAttribute('data-realtime-position', `${goodItems?.realtimePosition}`)
          componetInstance?.$el.setAttribute('data-realtime-from-cate-id', `list_realtime_from_cate_${this.catId || this.selectId || ''}`)
          componetInstance?.$el.setAttribute('data-realtime-from-goods-id', `list_realtime_from_goods_id_${curGoods?.goods_id || ''}`)
        }
        nextTick(()=> {
          setTimeout(() => {
            daEventExpose.subscribe({
              keycode: isStorePage ? `store_list_exposeCode\`2-3-2` : `plv2_list_exposeCode\`2-3-2`,
              type: 'list',
            })
          }, 200)
        })
      }
    },
    // 点击悬浮购物车
    handleClickCartBag() {
      // 点击悬浮购物车监控指标
      this.reportMetricCount({
        metricName: 'suspension_cart_click_total',
        tags: {
          page: this.catInfo.pageName,
        },
        message: 'Number of click cart bag',
      })
    },
    // 收集端上监控指标
    reportMetricCount({ metricName, tags, message }) {
      SIMetric.metricCount({
        metric_name: metricName,  // 指标名称
        tags, // 指标维度
        message // 日志信息
      })
    },
    // 针对点推商品的index值处理，涉及埋点坑位问题
    reportIndexFun(nutareIndex, item) {
      const { isPickInfoPage } = getListPageInfo(this.catInfo)
      if(isPickInfoPage) {
        return nutareIndex
      }
      // 列表的点推商品坑位上报需求和信息流的不同(注：坑位从1开始，+1逻辑是上报逻辑那自动处理的)
      // return this.localIndex // 不能用这个，否则会导致已经点推出来的商品再次渲染
      return item?.reportIndex
    },
    // 是否展示点推搜索词卡片
    showSearchWordCardFromRec(item) {
      // const { isEntityListPage, isSelectListPage, isSearch } = getListPageInfo(this.catInfo)
      // const { PsearchClickReco } = this.listAbtResult
      // const isAbtOpen = ((isEntityListPage || isSelectListPage) && ) || isSearch
      return (item && item.type === 'recommend-search-card') || false
    },
    handleVideoMounted(payload) {
      if (this.cardAutoPlayVideoControl) {
        this.cardAutoPlayVideoControl?.addVideo(payload)
      } else {
        this.initVideoList.push(payload)
      }
    },
    handleProductListScroll: throttle({
      func: function() {
        // this.log('触发收起拉通态 如果有的话 ！')
        cartTagTip.triggerQuickCartsEndtime()
      },
      wait: 600,
      options: {
        leading: false,
        trailing: false,
      }
    }),
    addExpandQuickCartClass() {
      if (Boolean(this.hitListCartExpand()) && Boolean(this.hitQuickCart())) {
        document.querySelector('body').classList.add('show-expand-quick-cart')
      }
    },
    clearExpandQuickCartClass() {
      if (Boolean(this.hitListCartExpand()) && Boolean(this.hitQuickCart())) {
        document.querySelector('body').classList.remove('show-expand-quick-cart')
      }
    }
  },
})
</script>

<style lang="less">
.product-list-v2 {
  &__list {
    --item-column-width: 4.52rem;
    padding: 0 0.32rem;
    .product-item-ctn {
      margin-bottom: 0.4267rem;
    }
    .product-card {
      margin-bottom: 0.16rem;
    }
    .flow-item-ctn {
      width: var(--item-column-width);
      border-radius: var(--item-outer-radius, 0);
      overflow: hidden;
      margin-bottom: var(--new-card-flow-cate-margin-bottom, 0.4267rem);
    }
  }

  &__list.actived-newproductcard {
    --item-column-width: 4.76rem;
    --item-outer-radius: 4px;
    --item-inner-radius: 2px;
    --new-card-flow-cate-bg: #fff;
    --new-card-flow-cate-margin-bottom: 0.16rem;

    padding: 0 0.16rem;
    background-image: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    background-size: 100% 160px;
    background-repeat: no-repeat;
  }

  &__list-lazy-holder {
    height: 6.02rem;
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.show-scroll-top.show-expand-quick-cart {
  /* stylelint-disable-next-line selector-class-pattern */
  .j-uptt {
    transform: translateY(-0.64rem);
  }
}
</style>
