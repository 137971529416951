import { AutoPlayVideoControl } from './AutoPlayVideoControl'
import {
  canScroll,
  checkNetworkIsWifi,
  debounce,
  monitorVideoSize,
} from './utils'
import { getCookie } from '@shein/common-function'

const VIDEO_SIZE_STORAGE_KEY = 'VIDEO_SIZE_STORAGE_KEY'

// 控制商卡视频自动播放
export class CardAutoPlayVideoControl {
  constructor({
    listContainerEl,
    scrollEl = window,
    initVideoList = [],
    cardIntersectionObserver,
  }) {
    if (!listContainerEl) {
      console.warn('缺少列表容器 listContainerEl!')
    }
    this.listContainerEl = listContainerEl
    this.cardIntersectionObserver = cardIntersectionObserver
    this.scrollEl = scrollEl
    this.handlerScroll = this.handlerScroll.bind(this)
    this.debouncePlayVideo = debounce(this.playVideo, 1000)
    this.debounceReportVideoSize = debounce(this.reportVideoSize, 1000)
    this.autoPlayVideoControl = new AutoPlayVideoControl({ limit: 2 })
    this.watchNetworkChange()
    initVideoList.forEach(payload => {
      this.addVideo(payload)
    })
  }
  // 滚动DOM元素
  scrollEl = null
  // 列表DOM元素
  listContainerEl = null
  // 监听视口内商卡
  cardIntersectionObserver = null

  // 防抖播放
  debouncePlayVideo = () => {}
  // 防抖上报视频尺寸
  debounceReportVideoSize = () => {}

  // 检查网络是否为wifi
  checkNetwork() {
    const isWifi = checkNetworkIsWifi()
    if (isWifi) {
      this.autoPlayVideoControl?.resumeRequest()
    } else {
      this.autoPlayVideoControl?.stopRequest()
    }
    return isWifi
  }
  watchNetworkChange() {
    if (typeof navigator !== 'undefined') {
      navigator.connection?.addEventListener('change', () => {
        const isWifi = this.checkNetwork()
        if (isWifi) {
          this.playVideo()
        }
      })
      this.checkNetwork()
    }
  }


  addVideo({ videoIns, videoEl, videoPlayer, autoplayVideoDuration } = {}) {
    if (!videoIns || !videoEl || !videoPlayer) {
      console.warn('缺少视频实例 videoIns, videoEl, videoPlayer!')
      return
    }
    let videoUrl = videoIns?.item?.video_infos?.[0]?.url || '' // 视频地址
    videoUrl = videoUrl.replace(/^https?:/, '')

    if (Number(getCookie('card_video_request_info'))) {
      // todo delete
      videoUrl = `${videoUrl}?GoodsId=${videoIns?.item?.goods_id}&index=${videoIns?.index}`
    }
    this.autoPlayVideoControl.addVideo({
      goodsId: videoIns?.item?.goods_id,
      videoUrl,
      videoPlayer, // video.js对象
      autoplayVideoDuration,
      onLoadData: () => {
        videoIns?.videoLoadEnd()
      },
      onChunkLoadCallback: this.onChunkLoadCallback,
    })
    // 防抖播放
    this.debouncePlayVideo()
    this.debounceReportVideoSize()
  }

  isWatchingScroll = false
  // 滚动计时器
  scrollingTimer = -1
  // 滚动位置
  scrollY = 0
  // 滚动处理
  handlerScroll() {
    if (this.scrollingTimer === -1 && canScroll()) {
      if (window.scrollY !== this.scrollY) {
        this.scrollingTimer = setTimeout(() => {
          const windowScrollY = window.scrollY
          this.scrollY = windowScrollY
          this.playVideo()
          this.scrollingTimer = -1
        }, 200) // 200 毫秒的延迟
      }
    }
  }
  // 节流监听滚动
  throttlingWatchScroll() {
    if (!this.isWatchingScroll) {
      this.isWatchingScroll = true
      this.scrollEl.addEventListener('scroll', this.handlerScroll)
    }
  }
  // 取消监听滚动
  unWatchScroll() {
    if (this.isWatchingScroll) {
      this.isWatchingScroll = false
      this.scrollEl.removeEventListener('scroll', this.handlerScroll)
      clearTimeout(this.scrollingTimer)
    }
  }

  // 找到需要自动播放的商卡
  findAutoplayVideoCard() {
    const intersectionCards = this.cardIntersectionObserver?.intersectionCards || []
    // 按坑位排序的商卡
    const sortCards = Array.from(intersectionCards).sort(
      (a, b) => a[0] - b[0],
    )
    let targetCardIns = null
    sortCards.some(obj => {
      const { cardEl, cardIns } = obj?.[1] || {}
      const cardAutoplayVideoPointEl = cardEl?.querySelector(
        '[name="autoplayPoint"]',
      )
      // 监控尺寸比例
      cardIns?.$refs?.['ProductCardAutoplayVideoRef']?.metricReportRatioSize()
      if (cardAutoplayVideoPointEl) {
        const { x, y } = cardAutoplayVideoPointEl.getBoundingClientRect()
        const pointEl = document.elementFromPoint(x, y)
        // 商卡指定位置（图片中间点） 是否包含在列表内
        if (
          pointEl &&
          this.listContainerEl &&
          this.listContainerEl.contains(pointEl)
        ) {
          targetCardIns = cardIns
          return true
        }
      }
    })
    return targetCardIns
  }

  currentCardIns = null
  playingGoodsId = null
  // 播放视频
  playVideo() {
    const targetCardIns = this.findAutoplayVideoCard()
    const goodsId = targetCardIns?.item?.goods_id
    if (this.playingGoodsId !== goodsId) {
      this.pauseVideo()
      this.playingGoodsId = goodsId
      this.currentCardIns =
        targetCardIns?.$refs?.['ProductCardAutoplayVideoRef']
      if (goodsId) {
        this.currentCardIns?.playVideo()
        this.autoPlayVideoControl.increasePriority(goodsId)
      }
    }
  }
  // 暂停播放
  pauseVideo() {
    this.currentCardIns?.pauseVideo()
    this.autoPlayVideoControl.decreasePriority(this.playingGoodsId)
    this.playingGoodsId = null
  }

  onChunkLoadCallback({ bytes, isCache }) {
    if (!isCache) {
      let size = Math.floor(Number(bytes?.byteLength) / 1024)
      const videoSizeStorage = Number(
        localStorage.getItem(VIDEO_SIZE_STORAGE_KEY),
      )
      if (videoSizeStorage) {
        size = size + videoSizeStorage
      }
      localStorage.setItem(VIDEO_SIZE_STORAGE_KEY, size)
    }
  }
  reportVideoSize() {
    if (typeof window === 'undefined') {
      return
    }
    const videoSizeStorage = Number(
      localStorage.getItem(VIDEO_SIZE_STORAGE_KEY),
    )
    localStorage.setItem(VIDEO_SIZE_STORAGE_KEY, 0)
    monitorVideoSize(
      {
        page_name: window?.SaPageInfo?.page_name,
      },
      videoSizeStorage,
    )
  }
}
