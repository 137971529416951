import MP4Box from 'mp4box'
import { Downloader } from './downloader'

export default class SourceBufferControl {
  constructor({
    videoUrl,
    videoPlayer,
    firstChunkSize = 300 * 1024,
    followChunkSize = 500 * 1024,
    autoplayVideoDuration,
    onLoadMeta = () => {},
    onLoadData = () => {},
    onChunkLoadCallback = () => {},
  }) {
    this.videoUrl = videoUrl
    this.videoPlayer = videoPlayer // video.js对象
    this.firstChunkSize = firstChunkSize
    this.followChunkSize = followChunkSize
    this.autoplayVideoDuration = autoplayVideoDuration
    this.onLoadMeta = onLoadMeta
    this.onLoadData = onLoadData
    this.onChunkLoadCallback = onChunkLoadCallback
    this.initSourceBuffer()
  }

  firstChunkSize = 0
  followChunkSize = 0
  videoUrl = ''
  videoPlayer = null // video.js对象
  ms = null // MediaSource 对象
  mp4file = null // MP4 文件对象
  downloader = null // 下载器
  notEndCnt = 0 // 未结束的轨道计数
  pendingInitCnt = 0 // 待初始化的轨道计数
  isMseEnd = false // MSE 加载是否结束
  loading = false // 加载状态
  initPromise = null // 初始化Promise
  onLoadMeta = null // 加载元数据回调
  onLoadData = null // 加载完视频资源后回调
  onChunkLoadCallback = null // 加载当前视频分片后回调
  chunkLoadEnd = -1 // 定制加载结束位置
  autoplayVideoDuration = 0 // 播放时长

  initSourceBuffer() {
    this.initPromise = new Promise(resolve => {
      // 创建 MediaSource 对象
      this.ms = new MediaSource()
      // 监听MediaSource已挂载video元素上
      this.ms.addEventListener('sourceopen', e =>
        this.handleSourceOpen(e, resolve),
      )
      this.videoPlayer.src({
        type: 'video/mp4',
        src: URL.createObjectURL(this.ms),
      })
      // 防止视频未能加载到足够播放的资源
      this.videoPlayer.on('canplay', () => {
        if (this.downloader.chunkLoadEnd === -1) {
          this.downloader.chunkLoadEnd = this.chunkLoadEnd
        }
      })
    })
  }

  handleSourceOpen(evt, initCallback) {
    const self = this
    // 释放URL.createObjectURL()
    URL.revokeObjectURL(evt.target.src)

    // console.log('handleSourceOpen')
    const ms = this.ms

    // 创建 MP4 文件对象
    const mp4file = MP4Box.createFile()
    this.mp4file = mp4file
    // 监听 MP4 文件准备就绪事件
    mp4file.onReady = info => {
      // console.log('mp4file is ready: ', info)

      ms.duration = info.duration / info.timescale

      self.linkMsAndMp4(info)
      self.initializeSegmentation(mp4file)
    }

    mp4file.onSegment = function (id, user, buffer, sampleNum, isEnd) {
      // console.log('onSegment', { id, user, buffer, sampleNum, isEnd })
      const ctx = user
      ctx.pending.push({
        id: id,
        buffer: buffer,
        sampleNum: sampleNum,
        isEnd: isEnd,
      })
      self.handleUpdateEnd(ctx)
    }

    this.downloader = new Downloader({
      url: this.videoUrl,
      chunkSize: this.firstChunkSize,
      onChunk({ bytes, isEof, isCache }) {
        self.onChunkLoadCallback &&
          self.onChunkLoadCallback({ bytes, isEof, isCache })
        const next = mp4file.appendBuffer(bytes, isEof)
        if (self.downloader.chunkSize !== self.followChunkSize) {
          self.downloader.chunkSize = self.followChunkSize
        }
        // console.log('onChunk', isEof)
        if (isEof) {
          setTimeout(() => {
            mp4file.flush()
          })
        } else {
          self.downloader.chunkStart = next
        }
      },
    })
    this.loadPromise = this.downloader.loadPromise
    this.downloader.start()
    initCallback && initCallback(this)

    // 点击跳转播放时间
    // this.videoEl.addEventListener("seeking", () => handleSeeking(this.videoEl, mp4file));
    // this.videoEl.addEventListener("timeupdate", () => handleSeeking(this.videoEl, mp4file));
  }

  initializeSegmentation(mp4file) {
    // 已经准备好接收segments
    mp4file.initializeSegmentation().forEach(seg => {
      const ctx = seg.user
      ctx.sb.appendBuffer(seg.buffer)
      ctx.pending.push({ isInit: true })
    })
  }

  linkMsAndMp4(mp4info) {
    const trackLen = mp4info.tracks.length
    // 初始化共享对象，包含媒体源、视频元素、加载状态等
    this.notEndCnt = trackLen // 未结束的轨道计数
    this.pendingInitCnt = trackLen // 待初始化的轨道计数
    this.isMseEnd = false // MSE 加载是否结束
    // 为每个轨道设置分段选项
    mp4info.tracks.forEach(track => {
      this.setSegmentOptions(track)
    })
  }

  /**
   * 为指定轨道设置分段选项
   * @param {Object} track - 轨道信息
   */
  setSegmentOptions(track) {
    const mime = `video/mp4; codecs="${track.codec}"`
    if (!MediaSource.isTypeSupported(mime)) {
      throw new Error('MSE does not support: ' + mime)
    }

    // 添加 SourceBuffer
    const sb = this.ms.addSourceBuffer(mime)
    const ctx = {
      sb, // SourceBuffer 对象
      id: track.id, // 轨道 ID
      pending: [], // 待处理的分段
    }

    // 监听 SourceBuffer 错误事件
    sb.addEventListener('error', e => console.error(e))
    // SourceBuffer.appendBuffer() 更新结果后触发
    sb.addEventListener('updateend', () => {
      // console.log('updateend', { ...ctx })
      this.handleUpdateEnd(ctx)
    })
    // 设置 MP4 文件的分段选项
    this.mp4file.setSegmentOptions(track.id, ctx, { nbSamples: 100 })
  }

  handleUpdateEnd(ctx) {
    // console.log('upd', ctx.sb.updating, ctx.isEof)
    if (ctx.sb.updating || this.ms.readyState !== 'open') return

    const seg = ctx.pending.shift()
    if (seg && seg.isInit) {
      this.pendingInitCnt--
    }

    if (this.pendingInitCnt === 0 && !this.loading) {
      this.loading = true
      this.loadMediaData()
      return
    }

    if (ctx.isEof && this.notEndCnt) {
      this.notEndCnt--
    }

    if (this.notEndCnt === 0 && !this.isMseEnd) {
      if (ctx.sampleNum) {
        this.mp4file.releaseUsedSamples(ctx.id, ctx.sampleNum)
        ctx.sampleNum = null
      }
      // 检查所有 SourceBuffer 是否都完成更新
      const sourceBuffers = this.ms.sourceBuffers
      let allBuffersUpdated = true
      for (let i = 0; i < sourceBuffers.length; i++) {
        if (sourceBuffers[i].updating) {
          allBuffersUpdated = false
          break
        }
      }
      if (
        allBuffersUpdated &&
        this.ms.readyState === 'open' &&
        !ctx.pending?.length &&
        !seg
      ) {
        this.isMseEnd = true
        // console.log('endOfStream')
        this.ms.endOfStream()
        this.onLoadData()
      }
    }

    if (seg && !seg.isInit) {
      ctx.sampleNum = seg.sampleNum
      ctx.isEof = seg.isEnd
      // console.log('appendBuffer', seg)
      ctx.sb.appendBuffer(seg.buffer)
    }
  }

  // 加载媒体数据(moov)
  loadMediaData() {
    // 定制播放区间的加载位置
    const duration = (+this.mp4file?.initial_duration || 0) / 1000 // 视频原定时长
    if (this.autoplayVideoDuration && this.autoplayVideoDuration < duration) {
      this.chunkLoadEnd = this.mp4file.seek(
        this.autoplayVideoDuration,
        true,
      ).offset
    }
    this.downloader.chunkStart = this.mp4file.seek(0, true).offset
    this.mp4file.start()
    const isNeedLoadMeta = this.downloader.resume()
    if (isNeedLoadMeta) {
      this.onLoadMeta()
    }
  }
}
